import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page introuvable" bodyClass="page" />
    <section className="section" aria-labelledby="page-title">
      <div className="container">
        <article>
          <header>
            <h1 id="page-title">Page introuvable</h1>
          </header>
          <p>La page que vous demandez n’existe plus.</p>
        </article>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
